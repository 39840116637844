
import './App.css';

function App() {
  return (
    <div className="App">
<h1>This is my react project</h1>
    </div>
  );
}

export default App;
